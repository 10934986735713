import { Box, Grid, Typography } from '@mui/material';
import ClaimService from '@src/api/services/claimService';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as errorObject from '@src/redux/error/actions';
import { useLoaderData, useNavigate } from 'react-router-dom';
import DescriptionIcon from '@mui/icons-material/Description';
import { dateFormatterBe } from '@src/components/util/util';
import AuthService from '@src/api/services/authService';
import { SET_LOADING } from '@src/redux/ui/actions';
import cookie from 'react-cookies';

const ClaimHistory = () => {
    const navigate = useNavigate();

    const [claimList, setClaimList] = useState<any>();
    const dispatchStore = useDispatch();
    // const claimList: any[] = []
    const claim = useLoaderData() as any;
    const refreshToken = () => {
        dispatchStore({ type: SET_LOADING, val: true });
        AuthService.tokenRefresh()
            .then((response) => {
                sessionStorage.setItem('token_econocom', `${response.headers.authorization}`);
                dispatchStore({ type: SET_LOADING, val: false });
            })
            .catch((err) => {
                sessionStorage.clear();
                cookie.remove('token_econocom');
            });
    };
    useEffect(() => {
        if (claim && claim?.status != 200) {
            dispatchStore({
                type: errorObject.SET_ERROR_OBJECT,
                val: {
                    dictionaryObject: 'verify_errors',
                    errCode: claim?.errCode,
                    message: claim?.response?.data?.message ? claim?.response?.data?.message : '',
                },
            });
            navigate('/dashboard');
        } else if (claim && claim?.status == 200) {
            setClaimList(claim);
        }
    }, [claim]);

    useEffect(() => {
        refreshToken();
    });
    return (
        <Box sx={{ paddingLeft: { xs: '20px', sm: '250px' }, marginTop: { xs: '50px', lg: '100px' }, paddingRight: '30px', marginBottom: '30px' }}>
            <Typography variant="h4">Claim History</Typography>
            <Grid container sx={{ marginTop: '20px' }}>
                {claimList?.data?._embedded?.claims?.map((data, index) => (
                    <Grid item xs={12} lg={6} key={index} sx={{ marginTop: '40px' }}>
                        <Grid container>
                            <Grid item xs={12} lg={3}>
                                <div className="img-container">
                                    <DescriptionIcon />
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={8}>
                                <Typography variant="body2">Claim Id:</Typography>
                                <div className="coloredDataElement">
                                    <Typography variant="body1">{data?.claimId}</Typography>
                                </div>
                                <Typography variant="body2">Date of Incident:</Typography>
                                <div className="coloredDataElement">
                                    <Typography variant="body1">{dateFormatterBe(new Date(data?.claimDate))}</Typography>
                                </div>
                                <Typography variant="body2">Claim Type:</Typography>
                                <div className="coloredDataElement">
                                    <Typography variant="body1">{data?.claimType?.name}</Typography>
                                </div>
                                <Typography variant="body2">Claim Status:</Typography>
                                <div className="coloredDataElement">
                                    <Typography variant="body1">{data?.status?.name}</Typography>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default ClaimHistory;

export const useClaimListLoader = async () => {
    const payLoad = {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        prt_id: JSON.parse(sessionStorage.getItem('userdata')!)?.contract?.partner?.prt_id,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        con_number: JSON.parse(sessionStorage.getItem('userdata')!)?.contract?.con_number,
        size: 50,
    };
    const res = await ClaimService.getClaimList(payLoad)
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return err;
        });
    return res;
};
