import { useSelector } from 'react-redux';
import resources from './resources';
import RootState from '@src/interfaces/RootState';

const languageId = sessionStorage.getItem('languageId');

export const config = {
    interpolation: {
        escapeValue: false, // React already does escaping
    },
    lng: languageId ? languageId : 'en',
    resources,
};

export { resources };
