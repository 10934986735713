import React, { useState } from 'react';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, IconButton, Toolbar, ListItem, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { NavLink } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import HomeIcon from '@mui/icons-material/Home';
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/Edit';

const DrawerComp = ({ handleLogout, mobileOpen, handleDrawerToggle, ...props }) => {
    const { window } = props;

    const navArray = [
        {
            icon: <HomeIcon />,
            target: 'dashboard',
            text: 'Dashboard',
        },
        {
            icon: <EditIcon />,
            target: 'newclaim',
            text: 'New Claim',
        },
        {
            icon: <HistoryIcon />,
            target: 'claimhistory',
            text: 'Claim History',
        },
    ];
    const drawer = (
        <div>
            <Toolbar />
            <Divider />
            <List>
                {navArray.map((data, index) => (
                    <NavLink key={data.text} to={data.target} style={{ color: 'black' }}>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>{data.icon}</ListItemIcon>
                                <ListItemText primary={data.text} />
                            </ListItemButton>
                        </ListItem>
                    </NavLink>
                ))}
            </List>
            <Divider />
            <List>
                {['Log out'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton onClick={handleLogout}>
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );
    const container = window !== undefined ? () => window().document.body : undefined;
    const drawerWidth = 220;

    return (
        <React.Fragment>
            <Drawer
                container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </React.Fragment>
    );
};

export default DrawerComp;
