import { LoginPayload } from '@src/interfaces/Login';
import axiosInstance from '../http/axiosSetup';

export default class AuthService {
    static performLogin = (body: LoginPayload) =>
        axiosInstance.post('authentications/microsite-login', body).then((res) => {
            return res;
        });
    // static performLogin = (body: LoginPayload) =>
    //     axiosInstance.post('2factor/login', body).then((res) => {
    //         return res;
    //     });
    static verifyCode = (body: Record<string, any>) =>
        axiosInstance.post<any>('authentications/microsite-verification', body).then((response) => {
            sessionStorage.setItem('token_econocom', `${response.headers.authorization}`);
            return response;
        });
    // static verifyCode = (body: Record<string, any>) =>
    //     axiosInstance.post<any>('2factor/verify', body).then((response) => {
    //         sessionStorage.setItem('token_econocom', `${response.headers.authorization}`);
    //         return response.data;
    //     });
    static tokenRefresh = () => {
        return axiosInstance.get('refresh/token').then((response) => {
            sessionStorage.setItem('token_econocom', `${response.headers.authorization}`);
            return response;
        });
    };
}
