import axiosInstance from '../http/axiosSetup';

export default class ClaimService {
    static getClaimList = (queryParams?: Record<string, any>) =>
        axiosInstance.get<any>('claims', { params: queryParams }).then((response) => {
            return response;
        });
    static getClaimTypes = (queryParams?: Record<string, any>) =>
        axiosInstance.get<any>('claim/type/list', { params: queryParams }).then((response) => {
            return response.data;
        });
    static claimAdd = (body: Record<string, any>) =>
        axiosInstance.post<any>('proximus/upsert/claim', body).then((response) => {
            return response.data;
        });
    static claimRegristration = (body: Record<string, any>) =>
        axiosInstance.post<any>('microsites/claim-registration', body).then((response) => {
            return response.data;
        });
    // static emailCheck = (body: Record<string, any>) =>
    //     axiosInstance.post<any>('validations/email-address-validation', body).then((response) => {
    //         return response.data;
    //     });
    static uploadFile = (body: Record<string, any>) =>
        axiosInstance.post<any>('attachments', body).then((response) => {
            return response.data;
        });
    // static claimAdd = (body: Record<string, any>) =>
    //     axiosInstance.post<any>('bwg/claim/upsert', body).then((response) => {
    //         return response.data;
    //     });
    static getContract = (partnerId: string, contractNumber: string) =>
        axiosInstance.get<any>(`contracts/${partnerId}/${contractNumber}`).then((response) => {
            return response.data;
        });
    static getDevice = (deviceId: string) =>
        axiosInstance.get<any>(`devices/${deviceId}`).then((response) => {
            return response.data;
        });
    static getDamageSubTypes = () =>
        axiosInstance.get<any>(`damage/subtype/1`).then((response) => {
            return response.data;
        });
}
