import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import uiReducer from './ui/reducer';
import utilReducer from './util/reducer';
import { configureStore } from '@reduxjs/toolkit';
// import logger from 'redux-logger';
// import createSagaMiddleware from 'redux-saga';
import errorReducer from './error/reducer';
import authReducer from './auth/reducer';

const reducer = combineReducers({
    auth: authReducer,
    error: errorReducer,
    form: reduxFormReducer,
    ui: uiReducer,
    util: utilReducer,
});

// const sagaMiddleWare = createSagaMiddleware();

const store = configureStore({
    reducer: reducer,
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }).concat(logger).concat(sagaMiddleWare),
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
});

export default store;
