import cookie from 'react-cookies';

import * as actionTypes from './actions';
const isBrowser = typeof window !== 'undefined';
const initialState = {
    modelList: null,
    brandList: null,
    submitted: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_MODEL_LIST:
            return {
                ...state,
                modelList: action.val,
            };
        case actionTypes.SET_BRAND_LIST:
            return {
                ...state,
                brandList: action.val,
            };
        case actionTypes.SET_SUBMITTED:
            return {
                ...state,
                submitted: action.val,
            };
        default:
            return state;
    }
}
