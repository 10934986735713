import * as actionTypes from './actions';
// const isBrowser = typeof window !== 'undefined';
const initialState = {
    isLoading: false,
    loggedIn: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case actionTypes.SET_LOADING:
            return {
                ...state,
                isLoading: action.val,
            };
        case actionTypes.LOGGED_IN:
            return {
                ...state,
                loggedIn: action.val,
            };
        default:
            return state;
    }
}
