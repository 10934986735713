import React, { Suspense, lazy } from 'react';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, useRouteError } from 'react-router-dom';
import PrivateRoute from '@src/contexts/auth/protectedRoute';
import { useClaimListLoader } from '@src/pages/claimHistory/index';
import RootLayout from '@src/components/layout/RootLayout';
import Error from '@src/pages/error/Error';
const Login = lazy(() => import('@src/pages/login/index'));
const ClaimNew = lazy(() => import('@src/pages/claimJourney/index'));
const ClaimHistory = lazy(() => import('@src/pages/claimHistory/index'));
const Dashboard = lazy(() => import('@src/pages/dashboard/index'));
const NotFound = lazy(() => import('@src/pages/notFound/index'));
const Layout = lazy(() => import('@src/components/layout/Layout'));

function ErrorBoundary() {
    const error = useRouteError();
    console.error(error);
    return <div>Error</div>;
}

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<RootLayout />}>
            <Route
                index
                element={
                    <Layout>
                        <Login />
                    </Layout>
                }
            />
            <Route path="dashboard" element={<PrivateRoute />}>
                <Route
                    index
                    element={
                        <Layout>
                            <Dashboard />
                        </Layout>
                    }
                />
            </Route>
            <Route path="newclaim" element={<PrivateRoute />}>
                <Route
                    index
                    element={
                        <Layout>
                            <ClaimNew />
                        </Layout>
                    }
                />
            </Route>
            <Route path="claimhistory" element={<PrivateRoute />}>
                <Route
                    index
                    element={
                        <Layout>
                            <ClaimHistory />
                        </Layout>
                    }
                    loader={useClaimListLoader}
                    errorElement={<ErrorBoundary />}
                />
            </Route>
            <Route path="*" element={<NotFound />} />
        </Route>
    )
);
const Router = () => {
    return (
        <>
            <Suspense fallback={<div>Loading...</div>}>
                <RouterProvider router={router} />
            </Suspense>
        </>
    );
};

export default Router;
